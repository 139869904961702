import { Injectable } from "@angular/core";
import { environment } from "src/app/config/environments/environment";
import { ErrorBase } from "src/app/core/models/error.model";
import { AppStorageService } from "src/app/core/app-store/app-storage.service";
import { AppRoutingService } from "src/app/core/routing/app-routing.service";
import { AuthService } from "@features/auth/shared/services/auth.service";

@Injectable({ providedIn: 'root' })
export class AppForDevelopService {
  constructor(
    private readonly authService: AuthService,
    private readonly router: AppRoutingService,
    private readonly storage: AppStorageService,
  ) { }

  runDevelopmentTasks = () => {
    this.validateConfigLocalStorage();

    if (!this.authService.isLoggedIn()) {
      this.handleLogin();
    }
  }

  private handleLogin = () => {
    const devUsername = environment.developer.username;
    const devPassword = environment.developer.password;
    this.authService.login(devUsername, devPassword)
      .subscribe({
        next: (response) => {
          console.warn('>>> Handle auto authenticate task: ', response);
          this.router.navigateToPreviousPage();
        },
        error: (error: ErrorBase) => {
          console.error(error);
        }
      });
  }

  private validateConfigLocalStorage = () => {
    this.storage.verifyStorageKey();
    console.log('>>> Handle validate localStorage config key task: Done');
  }
}
