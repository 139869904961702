import { HttpErrorResponse } from '@angular/common/http';
import { API_ENDPOINTS, API_VERSIONs } from '@config/api.config';

/**
 * Lớp ErrorBase chịu trách nhiệm chuyển đỗi HttpErrorResponse và lấy dữ liệu
 * từ thuộc tính error để dùng xử lý logic nhiệm vụ cho các service gọi api bằng HttpClient.
 */
export class ErrorBase {
  /**
   * @remarks apiVersion (e.g: 1.0.0, 2.0.0)
   */
  public apiVersion?: string | any;
  /**
   * Mã lỗi dạng số.
   */
  public code: number | any;

  /**
   * Tin nhắn lỗi tùy chọn.
   */
  public message?: string;

  /**
   * Mô tả về lỗi.
   * @remarks Cung cấp thông tin bổ sung về lỗi.
   */
  public desc?: any;

  /**
   * Dữ liệu mở rộng (metadata) liên quan đến lỗi.
   * @remarks Điều này có thể là bất kỳ dữ liệu bổ sung nào liên quan đến lỗi.
   */
  public metadata?: any;

  /**
   * @remarks Api path
   */
  public path?: any;

  /**
   * @remarks Error response timestamp from server.
   */
  public timestamp?: string;
  /**
   * Constructor của lớp HttpErrorHandler.
   * Nhận đối tượng HttpErrorResponse làm tham số và gán các thuộc tính tương ứng.
   *
   * @param httpErrorResponse HttpErrorResponse - Đối tượng chứa thông tin lỗi HTTP.
   */
  constructor(httpErrorResponse: HttpErrorResponse, apiVersion?: string | any) {
    this.apiVersion = apiVersion;

    switch (apiVersion) {
      case API_VERSIONs.VERSION_1_0_0:
        this.code = httpErrorResponse?.error?.code;
        this.message = httpErrorResponse?.error?.message ?? '';
        this.desc = httpErrorResponse?.error?.desc ?? '';
        this.metadata = httpErrorResponse?.error?.metadata ?? undefined;
        break;

      case API_VERSIONs.VERSION_2_0_0:
        this.code = httpErrorResponse?.error?.statusCode;
        this.message = httpErrorResponse?.error?.errorMessage ?? '';
        this.path = httpErrorResponse?.error?.path ?? '';
        this.timestamp = httpErrorResponse?.error?.timestamp ?? '';
        this.desc = httpErrorResponse?.error?.reasons ?? '';
        this.metadata = httpErrorResponse?.error?.metadata ?? undefined;
        break;

      default:
        console.warn(`Api version not-found - can not map response error`);
        break;
    }
  }
}
